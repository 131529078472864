import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

//workaround for reference error in keplergl lib
if (!('process' in window)) {
  // @ts-ignore
  window.process = {}
}

ReactDOM.render(
  <App />, document.getElementById('root')
);
