import { combineReducers } from "redux";
import DataReducer from "./DataReducer";
import LoadingReducer from "./LoadingReducer";

import { MapReducer } from "./MapReducer";

export const ContentReducer = combineReducers({
    data: DataReducer,
    map: MapReducer,
    loader: LoadingReducer
})