export function addUrlOptions(url: string, options: [string, string][] = []) {
    let i = 0
    for (const [key, option] of options) {
        if (i === 0) {
            url = url + "?"
        } else {
            url = url + "&"
        }
        url = url + `${key}=${option}`
        i = i + 1
    }
    return url
}