import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import './App.css';
import Content from "./components/Content";
import Header from "./components/Header";
import { getAppStore } from "./store/appStore";

function App() {
  return (
    <Provider store={getAppStore()}>
      <div className="app">
        <BrowserRouter>
          <Header />
          <div className="content">
            <Content />
          </div>
        </BrowserRouter >
      </div>
    </Provider>
  )
}

export default App;
