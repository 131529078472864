import copy from "../util/object/copy"
/**
 * @param  {number} filterconfig.start millis of time filter start
 * @param  {number} filterconfig.end millis of time filter end
 * @param {string} dataId of source
 * @return {any} timefilter config with given values and dataId.
 */
export function TimeFilter(filterconfig: { start: number, end: number }, dataId:string) {
    let timefilter = copy(require('./timefiltertemplate.json'))
    timefilter.id = Math.random().toString(36).slice(2)
    timefilter.dataId = dataId
    timefilter.value = [filterconfig.start, filterconfig.end]
    timefilter.hidden = true
    return timefilter
}