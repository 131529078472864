import { addUrlOptions } from "./add-url-options";
import { fetchUrl } from "./fetch-url";
import { Response } from "../../types/api/Response"

declare global {
    namespace NodeJS {
        interface ProcessEnv {
            REACT_APP_jwt_token: string;
            REACT_APP_tenant: string;
        }
    }
}

export async function getResponse(api_endpoint: string, options: [string, string][] = []) {
    let url = `${process.env.REACT_APP_historical_data_hostname}/${api_endpoint}`
    url = addUrlOptions(url, options)
    const response = fetchUrl(url)
    return response as Promise<Response>
}