import Plotly from 'plotly.js-dist-min'
import { useEffect, useRef, useState } from 'react';
declare global {
    namespace NodeJS {
        interface ProcessEnv {
            REACT_APP_MAPBOX_API: string;
        }
    }
}

Plotly.setPlotConfig({
    mapboxAccessToken: process.env.REACT_APP_MAPBOX_API
})

export default function Plot(props: { traces: any[], layout: any, config: any }) {
    const [plotId, _] = useState(Math.random().toString(36).slice(2))

    const tag = useRef(null as any);

    function drawPlot() {
        props.layout.height = tag?.current?.parentElement?.clientHeight
        props.layout.width = tag?.current?.parentElement?.clientWidth
        Plotly.newPlot(plotId, props.traces, props.layout, props.config)
    }

    useEffect(() => {
        drawPlot()
    }, [props.traces, props.config, props.layout]);

    window.addEventListener("resize", drawPlot);

    return <div ref={tag} className="time-linechart" id={plotId} />
}
