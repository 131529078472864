import copy from "../util/object/copy"
/**
 * @return {any} Layer config with given specifications.
 */
export default function PointLayer(column: string, label: string | undefined = undefined, dataId: string | undefined = undefined): any {
    if (label === undefined) {
        label = column
    }
    if (dataId === undefined) {
        dataId = 'data'
    }

    let layer = copy(require("./pointlayertemplate.json"))

    layer.id = Math.random().toString(36).slice(2)
    layer.config.dataId = dataId
    layer.config.label = label
    layer.visualChannels.colorField.name = column
    return layer
}