import {map, omit, pipe, mapObjIndexed} from "ramda"
import { renameKeys } from "ramda-adjunct"
import { Response } from "../types/api/Response"
import { getResponse } from "../util/io/get-response"
import {TimestampString} from "../types/api/TimestampString";
import {lineString} from "@turf/helpers";
import along from "@turf/along";
import length from "@turf/length";

declare global {
    namespace NodeJS {
        interface ProcessEnv {
            REACT_APP_data_explorer_endpoint: string
        }
    }
}

export async function getDataExplorerResponse(options: [string, string][] = []) {
    return getResponse(process.env.REACT_APP_data_explorer_endpoint, options) as Promise<Response>
}

export type DataExplorerRowObj = {
    "tijd": TimestampString,
    "lengtegraad" : number,
    "breedtegraad" : number,
    "_geojson" : string
    "deviceId": string,
    "naam" : string,
    "bron" : string,
    "autos" : number,
    "dauwpunt" : number,
    "luchtdruk": number,
    "luchtvochtigheid" : number,
    "potentialEvapotranspiration" : number,
    "regen intesiteit" : number,
    "regenvolume" : number,
    "rukwind": number,
    "temperatuur" : number,
    "tweewieler" : number,
    "voetgangers": number,
    "vrachtwagens": number,
    "wind": number,
    "windrichting": number,
    "zonne-uren" : number,
    "zonnesterkte" : number,
    "parkeerplaatsen" : number,
    "vrije parkeerplaatsen" : number,
    "bezette parkeerplaatsen" : number,
    "bezetting" : number
}
const replaceNAToNull = (value: any) => value === 'N/A' ? null : value;
const numberColumns = [
    'autos',
    'dauwpunt',
    'luchtdruk',
    'luchtvochtigheid',
    'potentialEvapotranspiration',
    'regen intesiteit',
    'regenvolume',
    'rukwind',
    'temperatuur',
    'tweewieler',
    'voetgangers',
    'vrachtwagens',
    'wind',
    'windrichting',
    'zonne-uren',
    'zonnesterkte',
    'parkeerplaatsen (auto)',
    'vrij parkeerplaatsen (auto)',
    'bezette parkeerplaatsen (auto)',
    'bezetting (auto)',
    'parkeerplaatsen (fiets)',
    'vrij parkeerplaatsen (fiets)',
    'bezette parkeerplaatsen (fiets)',
    'bezetting (fiets)'
]


/**
 * Get DataExplorer api DataFrame
 * @param  {{[key:string]:string}} options to pass to api/url query
 * @returns Promise<DataExplorerRowObj[]>
 */
export async function getDataExplorerCollection(options: [string, string][]): Promise<DataExplorerRowObj[]> {
    const response = await getDataExplorerResponse(options)

    const addLonLat = (obj: {geometry: string, breedtegraad: number | null, lengtegraad: number | null}) => {
        if (obj.geometry !== null) {
            let longitude = obj.lengtegraad
            let latitude = obj.breedtegraad
            if (!obj.breedtegraad || !obj.lengtegraad) {
                const streetLine = lineString(JSON.parse(obj.geometry))
                const streetMiddle = along(streetLine, length(streetLine) / 2)
                longitude = streetMiddle.geometry.coordinates[0]
                latitude = streetMiddle.geometry.coordinates[1]
            }
            return { ...obj, _geojson: JSON.stringify({type : "LineString", coordinates: JSON.parse(obj.geometry)}), breedtegraad: latitude, lengtegraad:  longitude}
        } else {
            return { ...obj, _geojson: null}
        }
    }

    const renameDict = {
        'regen_intensiteit': 'regen intensiteit',
        'zonne_uren': 'zonne-uren',
        'aantal_plaatsen_auto': 'parkeerplaatsen (auto)',
        'ratio_bezet_auto': 'bezetting (auto)',
        'aantal_vrij_auto': 'vrije parkeerplaatsen (auto)',
        'aantal_bezet_auto': 'bezette parkeerplaatsen (auto)',
        'aantal_plaatsen_fiets': 'parkeerplaatsen (fiets)',
        'ratio_bezet_fiets': 'bezetting (fiets)',
        'aantal_vrij_fiets': 'vrije parkeerplaatsen (fiets)',
        'aantal_bezet_fiets': 'bezette parkeerplaatsen (fiets)',
    }

    // Cast columns from string to number
    const toNumber = mapObjIndexed((value: string, key: string) => {
                if (((typeof value) !== 'string') || (!numberColumns.includes(key))) return value;
                let result = parseFloat(value)
                return Number.isNaN(result) ? value : result
            })

    const dropFields = omit(['geometry'])

    const dataexplorerCollection: DataExplorerRowObj[] = pipe(map(pipe(addLonLat, dropFields, renameKeys(renameDict), toNumber)))(response)
    return dataexplorerCollection.map(obj => map(replaceNAToNull, obj))
}

