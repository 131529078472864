import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import DataSelector from '../components/DataSelector';
import PointMap from '../components/PointMap';
import { DataSelectorContext, DataSelectorState } from '../context/DataSelectorContext';
import "./Charts.css";
import TimeLineChart from '../components/TimePlot';
import Loader from '../components/Loader';
import {useEffect, useState} from "react";
import {DataExplorerRowObj} from "../services/GetDataExplorer";

export default function Charts() {
    const [variables, setVariables] = useState({} as {[metric: string]: { datasetName: string, unit: string }})

    const datasets: {datasetName: string, data: DataExplorerRowObj[], units: {name: string, unit: string}[]}[] = useSelector((state: RootStateOrAny) => state.content.data.datasets.charts)
    useEffect(() => {
        const new_vars: {[metric: string]: { datasetName: string, unit: string }}= {}
        if (datasets) {
            datasets.forEach((dataset) => {
                dataset.units.forEach((variable: {name: string, unit: string}) => {
                    new_vars[variable.name] = {datasetName: dataset.datasetName, unit: variable.unit}
                })
            })
        }
        setVariables(new_vars)
    }, [datasets]);

    return <div className="chart-container">
        <DataSelector variables={variables}>
            <DataSelectorContext.Consumer>
                {(selectorState: DataSelectorState) => {
                    return <>
                        <div className="pointMap-wrapper">
                            <PointMap dataSelectorState={selectorState}/>
                        </div>
                        <div className="lineChart-wrapper">
                            <TimeLineChart dataSelectorState={selectorState}/>
                        </div>
                    </>
                }}
            </DataSelectorContext.Consumer>
        </DataSelector>
    </div>

}