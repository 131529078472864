import { TimeFilter } from "../../../config/FilterConfig"
import { Dataset } from "../../../types/data/Dataset"

export default function createFilters(datasets: Dataset[], filterOptions: any, mainFilterDatasetId:string) {
    //We create one enlarged filter as main time filter. The other mirrorfilters apply the same time filtering to the other datasets.
    const filters = []
    const mainFilter = TimeFilter(filterOptions, mainFilterDatasetId)
    mainFilter.enlarged = true
    filters.push(mainFilter)

    const mirrorFilterDatasetsIds = datasets.map((dataset: Dataset) => dataset.info.id).filter((id: string) => id !== mainFilterDatasetId)
    const mirrorFilters: { [key: string]: string } = {}
    for (const datasetName of mirrorFilterDatasetsIds) {
        const filter = TimeFilter(filterOptions, datasetName)
        filters.push(filter)
        mirrorFilters[filter.id] = mainFilter.id
    }
    return { filters, mirrorFilters }
}