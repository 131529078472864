import { concat, mergeLeft, mergeWithKey, reduce } from 'ramda';
import { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import KeplerMap from '../components/KeplerMap';
import { KeplerConfigState } from '../reducers/KeplerConfigReducer';
import copy from "../util/object/copy";
import createFilters from '../util/state/keplerMapReducer/createFilters';
import createLayers from "../util/state/keplerMapReducer/createLayers";
import {DataExplorerRowObj} from "../services/GetDataExplorer";


export default function Map() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({type: 'SET_LOADING_MAP', value: {loadingState: true}})
  }, []);

  const allDatasets: {datasetName: string, data : DataExplorerRowObj[], columns: string[], units: {name: string, unit: string}[]}[] = useSelector((state: RootStateOrAny) => state.content.data.datasets.map)
  useEffect(() => {
    if (allDatasets) {
      const configs = allDatasets.map((dataset ) => createLayers(dataset))
      // Merge objects, concat lists
      const concatOrMergeValues = (k: string, l: any, r: any) => { return k === "mirrorLayers" ? mergeLeft(l, r) : concat(l, r) }
      const { datasets, layers, mirrorLayers } = reduce(mergeWithKey(concatOrMergeValues), {})(configs)
      dispatch({
        type: "MUTATE_STATE", value: (state: KeplerConfigState) => {
          return { ...state, layers, datasets, mirrorLayers, filters: [], mirrorFilters: {} }
        }
      })
    }
  }, [allDatasets, dispatch])

  //create filters for datasets
  //@ts-ignore
  const datasets = useSelector((state) => state.content.map.keplerConfig.datasets)
  //@ts-ignore
  const startDate = useSelector((state) => state.content.data.dateRange.startDate)
  useEffect(() => {
    if (datasets) {
      const hourMillis = 1000 * 60 * 60
      const filterOptions = {start: startDate, end: startDate + 1.5 * hourMillis} //1.5h filter timeframe
      const {filters, mirrorFilters} = createFilters(datasets, filterOptions, 'luchtdruk')
      dispatch({type: 'SET_FILTER', value: filters})
      dispatch({type: 'ADD_MIRROR_FILTER', value: mirrorFilters})
    }
  }, [datasets, dispatch])

  useEffect(() => {
    if (datasets) {
      //Tooltip are dataset-specific and apply to all layers that visualize that dataset
      let tooltipFieldsToShow = {}
      const tooltipBaseColumns = ['tijd', 'naam', 'bron', 'breedtegraad', 'lengtegraad']
      for (const [_, datasetConfig] of Object.entries(allDatasets)) {
        tooltipFieldsToShow = {
          ...tooltipFieldsToShow, [datasetConfig.datasetName]: tooltipBaseColumns.map((variable: string) => {
            if (variable === 'tijd') {
              return {name: 'tijd', format: 'HH:MM DD-mm-yyyy'}
            } else {
              return {name: variable, format: null}
            }
          })
        }
      }
      dispatch({type: 'ADD_TOOLTIPFIELDSTOSHOW', value: tooltipFieldsToShow})

      let config = copy(require('../config/map_config.json'))
      dispatch({type: 'SET_CONFIG', value: config})
    }
  }, [dispatch, allDatasets])

  return (
    <KeplerMap keplerGlId="map" />
  )
}
