import { useEffect } from "react"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { Route, Routes, Navigate } from 'react-router-dom'
import Charts from "../pages/Charts"
import Landing from "../pages/Landing"
import Map from "../pages/Map"
import { initData } from "../util/state/data/initData"
import createDatasets from "../util/state/data/createDatasets";
import {DataExplorerRowObj} from "../services/GetDataExplorer";
import Loader from "./Loader";

export default function Content() {
    const dispatch = useDispatch()
    //load 
    useEffect(() => {
        const hourMillis = 60 * 60 * 1000
        const dayMillis = 24 * hourMillis
        const endDate = Date.now()
        let startDate = endDate - 3 * dayMillis
        const dateRange = { startDate, endDate }
        dispatch({ type: 'SET_DATE_RANGE', value: dateRange })
    }, [dispatch])


    const dateRange: { startDate: number, endDate: number } = useSelector((state: RootStateOrAny) => state.content.data.dateRange)
    useEffect(() => {
        if (!Number.isNaN(dateRange.startDate) && !Number.isNaN(dateRange.endDate)) {
            const queryOptions = [
                // ["order", "desc"],
                // ["from", `${new Date(dateRange.startDate).toISOString()}`],
                // ["to", `${new Date(dateRange.endDate).toISOString()}`]
            ]
            initData([], dispatch)

        }
    }, [dateRange])

    const dataHasUpdated = useSelector((state: RootStateOrAny) => state.content.data.dataHasUpdated)
    const data = useSelector((state: RootStateOrAny) => state.content.data.data)

    useEffect(() => {
        if (dataHasUpdated !== 0) {
            const {datasets_map, datasets_charts }  = createDatasets(data)
            dispatch({ type: 'SET_DATASETS_MAP', value: datasets_map })
            dispatch({ type: 'SET_DATASETS_CHARTS', value: datasets_charts })

        }
    }, [dataHasUpdated])

    return <><Loader></Loader><Routes>
        <Route path="/overview" element={<Landing/>}/>
        <Route path="/map" element={<Map/>}/>
        <Route path="/charts" element={<Charts/>}/>
        <Route path="/" element={<Navigate to="/overview"/>}/>
    </Routes></>
}