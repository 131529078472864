import { getAuthorizedHeaders } from "./get-headers";
import { Response } from "../../types/api/Response"

export async function fetchUrl(url: string, limit: number = -1) {

    const myHeaders = getAuthorizedHeaders()
    if (limit !== -1) {
        myHeaders.append("Range-Unit", "items");
        myHeaders.append("Range", `0-${limit}`);
        myHeaders.append("Prefer", "count=exact");
    }

    var requestOptions: RequestInit = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    const response = await fetch(url, requestOptions)
    const data = await response.json() as Response
    return data
}