import { filter, pipe, pluck, prop, propEq, uniqBy } from 'ramda';
import { DataSelectorState } from '../context/DataSelectorContext';
import Plot from "./Plot";

export default function TimeLineChart(props: { dataSelectorState: DataSelectorState }) {
    const { varName, deviceId, relevantDataset } = props.dataSelectorState
    const collection = relevantDataset
    let time = []
    let variable = []
    if ((collection !== undefined)) {
        const filteredCollection = pipe(filter(propEq('deviceId', deviceId)), uniqBy(prop('tijd')))(collection)
        time = pluck('tijd', filteredCollection)
        variable = pluck(varName, filteredCollection)
    }

    const trace1 = {
        x: time,
        y: variable,
        type: 'scatter',
    };
    const plotConfig = { displayModeBar: false }
    const plotLayout = require("./TimePlotLayout.json")
    return <Plot traces={[trace1]} layout={plotLayout} config={plotConfig} />
}