import { prop, uniqBy, pipe, project, filter } from 'ramda'
import { ChangeEventHandler, useEffect, useState } from "react"
import { RootStateOrAny, useSelector } from "react-redux"
import { DataSelectorContext } from '../context/DataSelectorContext'
import "./DataSelector.css"
import {DataExplorerRowObj} from "../services/GetDataExplorer";

export default function DataSelector(props: { children?: React.ReactNode, variables: { [metric: string]: {datasetName: string, unit: string} } }) {
    const metricNames = Object.keys(props.variables)
    const [varName, setVariable] = useState(metricNames[0])
    useEffect(() => {
        if (!varName && metricNames.length !== 0) {
            setVariable(metricNames[0])
        }
    }, [metricNames]);
    const onVariableSelectChange: ChangeEventHandler<HTMLSelectElement> = (event) => setVariable(event.target.value)

    const [deviceIds, setDeviceIds] = useState([] as { deviceId: string | number, naam: string }[])
    const [deviceId, setDeviceId] = useState(undefined as string | number | undefined)
    const datasets: {datasetName: string, data : DataExplorerRowObj[], columns: string[], units: {name: string, unit: string}[]}[] = useSelector((state: RootStateOrAny) => state.content.data.datasets.charts)
    const relevantDataset = varName === undefined ? [] : datasets.find(dataset => dataset.datasetName === props.variables[varName].datasetName)?.data || []

    useEffect(() => {
        if (varName) {
            const getUniqueDevices = pipe(
                filter((row: { [varName: string]: number}) => row[varName] !== null),
                project(["deviceId", "naam"]),
                uniqBy(prop('deviceId')),
                filter((row: { deviceId: any, [key: string]: number}) => row.deviceId !== undefined )
            )
            let deviceIds: { deviceId: string | number, naam: string }[] = getUniqueDevices(relevantDataset)

            let new_deviceId = deviceIds.length > 0 ? deviceIds[0].deviceId : undefined
            setDeviceIds(deviceIds)
            if (!deviceId || !deviceIds.map(device => device.deviceId).includes(deviceId)) {
                setDeviceId(new_deviceId)
            }
        }
    }, [datasets, varName])
    const onDeviceSelectChange: ChangeEventHandler<HTMLSelectElement> = (event) => setDeviceId(event.target.value)

    const context = { varName, deviceId,  relevantDataset}

    return <>
        < div className="select-panel" >
            <p className="variable">Data laag:</p>
            <select className="variable" data-testid="variable-select" onChange={onVariableSelectChange}>
                {metricNames.map((x: string | number) => <option value={x} key={x}>{`${x} (${props.variables[x].unit})`}</option>)}</select>
            <p className="deviceId">Meetpunt:</p>
            <select className="deviceId" data-testid="deviceId-select" onChange={onDeviceSelectChange} value={deviceId}>
                {deviceIds.map((x) => <option value={x.deviceId} key={x.deviceId}>{x.naam && x.naam.trim() !== '' ? x.naam : x.deviceId}</option>)}
            </select>
        </div >
        <DataSelectorContext.Provider value={context}>
            {props.children}</DataSelectorContext.Provider>
    </>
}