
import { configureStore } from '@reduxjs/toolkit';
import { taskMiddleware } from "react-palm/tasks";
import { ContentReducer } from '../reducers/ContentReducer';

export function getAppStore(preloadedState: any = undefined) {
  return configureStore({
    reducer: {
      content: ContentReducer
    },
    middleware: [taskMiddleware],
    preloadedState
  })
}

