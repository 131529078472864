import { all, filter, isNil, map, not, pipe, pluck, project, prop, props, any, omit } from 'ramda'
import {DataExplorerRowObj} from "../../../services/GetDataExplorer";
export const datasetConfigs: { name: string, variables: {name : string, unit: string}[], sources: string[] }[] = [
    { name: "luchtdruk", variables: [{name: 'luchtdruk', unit: 'hPa'}], sources: ['vlinder', 'isagri'] },
    { name: "temperatuur", variables: [{name: 'temperatuur', unit: '°C'}], sources: ['vlinder', 'isagri'] },
    { name: "dauwpunt", variables: [{name: 'dauwpunt', unit: '°C'}], sources: ['isagri'] },
    { name: "luchtvochtigheid", variables: [{name: 'luchtvochtigheid', unit: '%'}], sources: ['vlinder', 'isagri'] },
    { name: "neerslag", variables: [{name: 'regenvolume', unit: 'mm/mm²'}, {name: 'regen intensiteit', unit:'mm/h'}], sources: ['vlinder', 'isagri'] },
    { name: "wind", variables: [{name: 'wind', unit: 'km/h'}, {name: 'rukwind', unit: 'km/h'}, {name: 'windrichting', unit: '°'}], sources: ['vlinder', 'isagri'] },
    { name: "zon", variables: [{name: 'zonnesterkte', unit: 'J/cm²'}, {name: 'zonne-uren', unit: 'minuten'}], sources: ['isagri'] },
    { name: "tweewielers", variables: [{name: 'tweewieler', unit: 'aantal'}], sources: ['signco', 'telraam'] },
    { name: "voetgangers", variables: [{name: 'voetgangers', unit: 'aantal'}], sources: ['telraam'] },
    { name: "autos", variables: [{name: 'autos', unit: 'aantal'}], sources: ['telraam'] },
    { name: "vrachtwagens", variables: [{name: 'vrachtwagens', unit: 'aantal'}], sources: ['telraam'] },
    { name: "potentialEvapotranspiration", variables: [{name : 'potentialEvapotranspiration', unit: "mm" }], sources: ['isagri'] },
    { name: "parkeerplaatsen (auto)", variables: [
        {name: 'parkeerplaatsen (auto)', unit: 'aantal'},
        {name: 'vrije parkeerplaatsen (auto)', unit: 'aantal'},
        {name: 'bezette parkeerplaatsen (auto)', unit: 'aantal'},
        {name: 'bezetting (auto)', unit: 'ratio'}
    ], sources: ['yunex'] },
    { name: "parkeerplaatsen (fiets)", variables: [
        {name: 'parkeerplaatsen (fiets)', unit: 'aantal'},
        {name: 'vrije parkeerplaatsen (fiets)', unit: 'aantal'},
        {name: 'bezette parkeerplaatsen (fiets)', unit: 'aantal'},
        {name: 'bezetting (fiets)', unit: 'ratio'}
    ], sources: ['yunex'] }
]


/**
 * Create a dataset for each datasetStructure entry. Each dataset contains base_columns and measurementColumns, and _geojson if it is present.
 * @param  {DataExplorerRowObj[]} all_data all data to be split up into datasets
 * @returns {name, data, units}[]
 */
export default function createDatasets(all_data: DataExplorerRowObj[]) {
    const datasets_map: {datasetName: string, data : DataExplorerRowObj[], columns: string[]}[] = []
    const datasets_charts: {datasetName: string, data : DataExplorerRowObj[], columns: string[]}[] = []

    datasetConfigs.forEach(datasetConfig => {
        const {name, variables, sources} = datasetConfig
        const base_columns = ['tijd', 'naam', 'breedtegraad', 'lengtegraad', 'deviceId','bron', '_geojson']
        const data = all_data.filter((obj) => sources.includes(obj.bron))

        //create dataset
        const names = pluck('name', variables)
        let selectColumns = base_columns.concat(names)
        const collectData =  project(selectColumns)
        let resultCollection = collectData(data)
        const dataset_charts = {datasetName: name, data: resultCollection, columns: selectColumns, units: variables}
        datasets_charts.push(dataset_charts)

        const dropMissing = filter(pipe(props(names), all(isNil), not))
        resultCollection = dropMissing(resultCollection)
        const hasGeoJson = pipe(map(prop('_geojson')), any(pipe(isNil, not)))(resultCollection)
        if (!hasGeoJson) {
            resultCollection = resultCollection.map((obj: {'_geojson' : string}) => omit(['_geojson'], obj))
            selectColumns = selectColumns.filter((column :string) => column !== '_geojson')
        }
        const dataset_map = {datasetName: name, data: resultCollection, columns: selectColumns, units: variables}
        datasets_map.push(dataset_map)
    })

    return {datasets_map: datasets_map, datasets_charts: datasets_charts}
}