import "./Landing.css";


export default function Landing() {
    return (
        <>
            <div className="landing-container">
                <h1 className="header-text">Sensordata als katalysator voor verandering.</h1>
                <div className="text-container">
                    <h1>Data Verkenner</h1>
                    <p>
                        De Smart Innovation Factory verzamelt heel wat sensordata in Mechelen en omgeving. Je kan deze
                        data zelf verkennen aan de hand van de <b><a href="/map">kaart</a></b> of aan de hand van
                        de <b><a href="/charts">grafieken</a></b>.
                        De gegevens die worden weergegeven, worden real-time verzameld. De visualisaties geven slechts
                        een
                        zicht op de voorbije drie dagen aan informatie. De volledige historiek voor de metingen wordt
                        ook
                        bijgehouden en is beschikbaar op aanvraag.<br/><br/>
                        Wil je verder aan de slag met meer data ? Geef ons dan een seintje !<br/>
                    </p>
                    <a href="https://www.smartinnovationfactory.be/contact" target="_blank">
                        <div className="button">contact</div>
                    </a>
                </div>

            </div>
           <div className="footer">
            <img src={require("./images/stad_mechelen.png")} alt="Stad Mechelen" />
            <img src={require("./images/igemo.png")} alt="IGEMO" />
            <img className="add_padding_top_bottom" src={require("./images/cronos.png")} alt="cronos groep" />
            <img className="add_padding_left" src={require("./images/VLAIO.png")} alt="VLAIO" />
          </div>
        </>
    )
}