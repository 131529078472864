import "./Loader.css";
import {RootStateOrAny, useSelector} from "react-redux";
import { any, filter, pipe, pluck, propEq, values } from 'ramda';

export default function Loader() {
    const loader = useSelector((state: RootStateOrAny) => state.content.loader)
    const isLoading = pipe(
            values,         // Extract values of the loadings object
            pluck('loadingState'),
            any(Boolean)
    )(loader.loadings);
    const displayText = pipe(
            filter(propEq('loadingState', true)),
            values,         // Extract values of the loadings object
            pluck('text'),
    )(loader.loadings);
    const loadingTextElements = displayText.map((text: string, index: number) => (
        <div key={index}>{text}</div>
      ));

    return ( isLoading && <div className="overlay">
    <div className="loader-container">
      <div className="loader">
        <div className="bar bar1"></div>
        <div className="bar bar2"></div>
        <div className="bar bar3"></div>
        <div className="bar bar4"></div>
        <div className="bar bar5"></div>
        <div className="bar bar6"></div>
      </div>
        <span className="loading-text">{loadingTextElements}</span>
    </div>
  </div>
    )
}