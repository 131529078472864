
export type LoadingState = {
    loadings: {
        data : {text : string, loadingState: boolean},
        map : {text : string, loadingState: boolean},
        chart : {text : string, loadingState: boolean}

    }
}

const initialState: LoadingState = {
    loadings: {
        data : {text: "Fetching data...", loadingState: true},
        map : {text: "Loading map...", loadingState: false},
        chart : {text: "Loading charts...", loadingState: false},

    },
}

// Use the initialState as a default value
export default function LoadingReducer(state: LoadingState = initialState, action: any) {
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
        // Do something here based on the different types of action
        case "SET_LOADING_DATA":
            return { ...state, loadings: {...state.loadings, data: {...state.loadings.data, ...action.value}}}
        case "SET_LOADING_MAP":
            return { ...state, loadings: {...state.loadings, map: {...state.loadings.map, ...action.value}}}
        case "SET_LOADING_CHART":
            return { ...state, loadings: {...state.loadings, chart: {...state.loadings.chart, ...action.value}}}
        default:
            // If this reducer doesn't recognize the action type, or doesn't
            // care about this specific action, return the existing state unchanged
            return state
    }
}