import { pluck, prop, propEq, uniqBy, find } from 'ramda';
import { DataSelectorState } from "../context/DataSelectorContext";
import Plot from './Plot';
import {DataExplorerRowObj} from "../services/GetDataExplorer";
import DataSelector from "./DataSelector";

export default function PointMap(props: { dataSelectorState: DataSelectorState}) {
    const { dataSelectorState} = props
    const collection = dataSelectorState.relevantDataset
    const findDevice = (metricName: string | number | undefined, deviceId: undefined | number | string) => {
        if ((metricName === undefined) || (deviceId === undefined)) {
            return undefined
        }
        const deviceRow = find(propEq("deviceId", deviceId))(collection)
        if (deviceRow === undefined) {
            return undefined
        }
        return deviceRow
    }

    const selectedDevice = findDevice(dataSelectorState.varName, dataSelectorState.deviceId) ?? {}

    const otherDevices = uniqBy(prop('deviceId'))(collection?.filter((row:DataExplorerRowObj) => row[dataSelectorState.varName as keyof DataExplorerRowObj] !== null)).filter((device: { deviceId: string | number }) => device.deviceId !== selectedDevice.deviceId)

    const selectedDeviceTrace = {
        lon: [selectedDevice.lengtegraad],
        lat: [selectedDevice.breedtegraad],
        text: [!selectedDevice.naam || selectedDevice.naam === " " ? selectedDevice.deviceId : selectedDevice.naam],
        customdata: [[selectedDevice.bron]],
        type: 'scattermapbox',
        mode: "markers+text",
        marker: {
            size: 20
        },
        textposition: "top right",
        hovertemplate: "%{text}<br>Bron: %{customdata[0]}<extra></extra>",
        textFont: {
            size: 32,
        }
    };
    const otherDevicesTrace = {
        lon: pluck('lengtegraad', otherDevices),
        lat: pluck("breedtegraad", otherDevices),
        text: otherDevices.map((device: DataExplorerRowObj) => !device.naam || device.naam === " " ? device.deviceId : device.naam),
        customdata: otherDevices.map((device: DataExplorerRowObj) => [device.bron]),
        type: 'scattermapbox',
        mode: "markers+text",
        marker: {
            color: 'grey',
            size: 16
        },
        hovertemplate: "%{text}<br>Bron: %{customdata[0]}<extra></extra>",
        textposition: "top right",
        textFont: {
            size: 32,
        }
    }

    const traces = [selectedDeviceTrace, otherDevicesTrace]
    const config = { displayModeBar: false }
    const layout = require("./PointMapLayout.json")
    return <Plot traces={traces} layout={layout} config={config} />
}
