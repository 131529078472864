declare global {
    namespace NodeJS {
        interface ProcessEnv {
            REACT_APP_jwt_token: string;
            REACT_APP_tenant: string;
            REACT_APP_x_api_key: string
        }
    }
}

export function getAuthorizedHeaders(jwt_token: string = "", tenant: string = ""): Headers {
    if (jwt_token === "") {
        jwt_token = process.env.REACT_APP_jwt_token;
    }
    if (tenant === "") {
        tenant = process.env.REACT_APP_tenant;
    }

    const myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${jwt_token}`);
    // myHeaders.append("Accept-Profile", `tn_${tenant}`);
    myHeaders.append("X-API-Key", process.env.REACT_APP_x_api_key);
    return myHeaders;
}