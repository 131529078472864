import copy from "../util/object/copy"
/**
 * @param  {string} colorColumn for color scale
 * @param  {string} label
 * @param  {string} dataId of dataset
 * @param {string} geoColumn
 * @return {any} layer config of polygonlayer
 */
export function PolygonLayer(colorColumn: string, label: string, dataId: string, geoColumn = "_geojson") {
    let layer = copy(require("./polygonlayertemplate.json"))

    layer.id = Math.random().toString(36).slice(2)

    layer.config.dataId = dataId
    layer.config.label = label
    layer.visualChannels.colorField.name = colorColumn
    layer.visualChannels.strokeColorField.name = colorColumn
    return layer
}