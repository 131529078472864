import KeplerGl from "kepler.gl";
import { addDataToMap, forwardTo, layerConfigChange, receiveMapConfig, setFilter } from "kepler.gl/actions";
import KeplerGlSchema from 'kepler.gl/schemas';
import { useEffect } from "react";
import { RootStateOrAny, shallowEqual, useDispatch, useSelector } from "react-redux";
import { AutoSizer } from 'react-virtualized/dist/commonjs/AutoSizer';
import { KeplerConfigState } from "../reducers/KeplerConfigReducer";
import copy from "../util/object/copy";
import collectionToRows from "../util/table/collection-to-rows";
import './KeplerMap.css';

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_MAPBOX_API: string;
    }
  }
}
/**
 * React functional component.
 * @returns KeplerGl element populated with data and config from keplerConfig
 */
export default function KeplerMap({ keplerGlId }: { keplerGlId: string }) {
  const keplerConfigState: KeplerConfigState = useSelector((state: RootStateOrAny) => state.content.map.keplerConfig)
  const dispatch = useDispatch()
  const keplerGlDispatch = forwardTo(keplerGlId, dispatch)
  //Update KeplerGl visualization whenever the keplerMapState updates
  useEffect(() => {
    const { datasets, config, layers, filters, tooltipFieldsToShow } = keplerConfigState
    if (datasets) {
    // Function to be executed after a delay
      const delayRender = () => {
        //build KeplerGl config with layer and filters
        let myConfig = config
        myConfig.config.visState.filters = copy(filters)
        let myLayers = layers
        if (layers.length > 0) {
          myLayers[0].config.isVisible = true
        }
        myConfig.config.visState.layers = myLayers

        myConfig.config.visState.interactionConfig.tooltip = {
          "fieldsToShow": tooltipFieldsToShow,
          "compareMode": false,
          "compareType": "absolute",
          "enabled": true
        }
        myConfig = KeplerGlSchema.parseSavedConfig(myConfig);
        //convert datasets
        const datasetsWithTable = datasets.map((dataset) => ({
              info: dataset.info,
              data: {
                rows: collectionToRows(dataset.data, dataset.fields.map((field: { name: string }) => field.name)),
                fields: dataset.fields
              }
            }
        ))
        keplerGlDispatch(receiveMapConfig(myConfig, {centerMap: false, keepExistingConfig: false}));
        keplerGlDispatch(
            addDataToMap({
              datasets: datasetsWithTable,
            })
        );
      };

      // delay sending data to kepler -> loading widget has time to appear
      const delayTimeoutRender = setTimeout(() => {
        delayRender();
      }, 50);

      const delayLoadingWidget = () => {
      // Another logic here
            dispatch({type: 'SET_LOADING_MAP', value: {loadingState: false}})
      };

      const delayTimeoutLoadingWidget = setTimeout(() => {
        delayLoadingWidget();
      }, 3500);

      // Clear the timeout to avoid executing the function if the component unmounts
      return () => {
        clearTimeout(delayTimeoutRender);
        clearTimeout(delayTimeoutLoadingWidget);
      };
    }
  }, [keplerConfigState, dispatch])

  // Whenever a mainFilter updates its values, set same values to the mirrorFilter
  const filterState: any[] = useSelector((state: RootStateOrAny) => state.content.map.keplerGl[keplerGlId]?.visState?.filters) ?? []
  const { mirrorFilters } = keplerConfigState
  useEffect(() => {
    if (mirrorFilters) {
      for (const [filterId, mainFilterId] of Object.entries(mirrorFilters)) {
        const idx = filterState.findIndex(filter => filter.id === filterId)
        const mainFilter = filterState.find(filter => filter.id === mainFilterId)
        if ((mainFilter === undefined) || (idx === -1)) continue
        if (!shallowEqual(filterState[idx].value, mainFilter.value)) {
          keplerGlDispatch(setFilter(idx, 'value', copy(mainFilter.value)))
        }
      }
    }
  }, [filterState, mirrorFilters, dispatch])

  // Whenever a mainLayer updates its isVisisble, set same isVisibile to the mirrorFilter
  const layerState: any[] = useSelector((state: RootStateOrAny) => state.content.map.keplerGl?.[keplerGlId]?.visState?.layers) ?? []

  const { mirrorLayers } = keplerConfigState
  useEffect(() => {
    if (mirrorLayers) {
      for (const [layerId, mainLayerId] of Object.entries(mirrorLayers)) {
        const layer = layerState.find(layer => layer.id === layerId)
        const mainLayer = layerState.find(layer => layer.id === mainLayerId)
        if ((mainLayer === undefined) || (layer === undefined)) continue
        if (layer.config.isVisible !== mainLayer.config.isVisible) {
          keplerGlDispatch(layerConfigChange(layer, {isVisible: mainLayer.config.isVisible}))
        }
      }
    }
  }, [layerState, mirrorLayers, dispatch])

  return <div className="keplermap">
    <AutoSizer>
      {/* @ts-ignore */}
      {({ height, width }) => (
        <KeplerGl
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API}
          id={keplerGlId}
          width={width}
          height={height}
          getState={(state: { content: { map: { keplerGl: any; }; }; }) => state.content.map.keplerGl}
        />
      )}
    </AutoSizer>
  </div>
}