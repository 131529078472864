import PointLayer from "../../../config/PointLayerConfig"
import { PolygonLayer } from "../../../config/PolygonLayerConfig"
import {DataExplorerRowObj} from "../../../services/GetDataExplorer";

/**
 * Create a dataset for each datasetStructure entry. Each dataset contains base_columns and measurementColumns, and _geojson if it is present. For each measurementColumn create a PointLayer.
 * If all datasets have a '_geojson' column, add a hidden geojson mirrorLayer for each PointLayer, which 'mirrors' its visibility.
 * @param  {Collection<any>[]} collections to be combined into datasets
 * @param  {{[key:string]:string[]}} datasetStructure {datasetName:measurementColumns}. 
 * @returns {layers, datasets, mirrorLayers}
 */
export default function createLayers(dataset : {datasetName: string, data: DataExplorerRowObj[], columns: string[], units: {name: string, unit: string}[]}) {
    const layers = []
    const mirrorLayers: { [key: string]: string } = {}
    const {datasetName, data, columns, units} = dataset
    const fields = columns.map(column => { return { name: column, format: "" } })
    const dataset_kepler = { info: { id: datasetName, label: datasetName }, data: data, fields: fields }
    //make layer

    for (const column of units) {
        const {name, unit} = column
        const pointLayer = PointLayer(column.name, `${name} (${unit})`, datasetName)
        layers.push(pointLayer)
        //make hidden PolygonLayers and set them to mirror the PointLayer of the same column
        if (columns.includes('_geojson')) {
            const geoLayer = PolygonLayer(name, `${name} (${unit})`, datasetName)
            geoLayer.config.hidden = true
            mirrorLayers[geoLayer.id] = pointLayer.id
            layers.push(geoLayer)
            pointLayer.config.visConfig.radius = 15
        }
    }

    return { layers: layers, datasets: [dataset_kepler], mirrorLayers: mirrorLayers }
}