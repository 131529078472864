import { Dataset } from "../types/data/Dataset"

export type KeplerConfigState = {
    config: any,
    filters: any[],
    layers: any[],
    mirrorFilters: { [key: string]: string },
    mirrorLayers: { [key: string]: string },
    datasets: Dataset[],
    tooltipFieldsToShow: { [key: string]: { name: string, format: string | null }[] }
}

const initialState = {
    config: require('../config/base_config.json'),
    filters: [],
    layers: [],
    mirrorFilters: {},
    mirrorLayers: {},
    datasets: [],
    tooltipFieldsToShow: {}
}

// Use the initialState as a default value
export default function KeplerConfigReducer(state: KeplerConfigState = initialState, action: any) {
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
        // Do something here based on the different types of action
        case "MUTATE_STATE":
            return action.value(state)
        case "SET_CONFIG":
            return { ...state, config: action.value }
        case "SET_FILTER":
            return { ...state, filters: action.value }
        case "ADD_MIRROR_FILTER":
            return { ...state, mirrorFilters: { ...state.mirrorFilters, ...action.value } }
        case "ADD_LAYER":
            return { ...state, layers: state.layers.concat(action.value) }
        case "ADD_MIRROR_LAYER":
            return { ...state, mirrorLayers: { ...state.mirrorLayers, ...action.value } }
        case "ADD_DATASET":
            return { ...state, datasets: state.datasets.concat(action.value) }
        case "ADD_TOOLTIPFIELDSTOSHOW":
            return { ...state, tooltipFieldsToShow: { ...state.tooltipFieldsToShow, ...action.value } }
        default:
            // If this reducer doesn't recognize the action type, or doesn't
            // care about this specific action, return the existing state unchanged
            return state
    }
}