import { Link } from "react-router-dom";
import "./Header.css";
import {RootStateOrAny, useSelector} from "react-redux";
import Loader from './Loader';

export default function Header() {
    return (
        <div className="float header">
            <nav className="header-container">
                <ul className="header-button-list">
                     <li className="header-element">
                        <Link className="header-link" to="/overview">
                            Overview
                        </Link>
                    </li>
                    <li className="header-element">
                        <Link className="header-link" to="/map">
                            Kaart
                        </Link>
                    </li>
                    <li className="header-element">
                        <Link className="header-link" to="/charts">
                            Grafieken
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
}