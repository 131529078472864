import {DataExplorerRowObj} from "../services/GetDataExplorer";

export type DataState = {
    dateRange: { startDate: number, endDate: number },
    dataHasUpdated: number,
    datasets: {
        map : {datasetName: string, data : DataExplorerRowObj[], columns: string[], units: {name: string, unit: string}[]}[],
        charts : {datasetName: string, data : DataExplorerRowObj[], columns: string[], units: {name: string, unit: string}[]}[]
    },
    data : DataExplorerRowObj[]
}

const initialState: DataState = {
    dateRange: { startDate: NaN, endDate: NaN },
    dataHasUpdated: 0,
    datasets: {
        map : [],
        charts : []
    },
    data : []
}

// Use the initialState as a default value
export default function DataReducer(state: DataState = initialState, action: any) {
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
        // Do something here based on the different types of action
        case "SET_DATA":
            return { ...state, data: action.value , dataHasUpdated: state.dataHasUpdated + 1}
        case "SET_DATASETS_MAP":
            return { ...state, datasets: { ...state.datasets, map: action.value} }
        case "SET_DATASETS_CHARTS":
            return { ...state, datasets: { ...state.datasets, charts: action.value} }
        case "SET_DATE_RANGE":
            return { ...state, dateRange: action.value }
        default:
            // If this reducer doesn't recognize the action type, or doesn't
            // care about this specific action, return the existing state unchanged
            return state
    }
}